import React from 'react';

import { st, classes } from './Spinner.st.css';

export interface ISpinnerProps {
  role?: 'component' | 'element';
  className?: string;
}

export const Spinner: React.FC<ISpinnerProps> = ({
  role = 'component',
  className,
}) => {
  return (
    <svg
      className={st(
        classes.root,
        {
          role,
        },
        className,
      )}
      viewBox="0 0 50 50"
    >
      <circle
        className={classes.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="3"
      />
    </svg>
  );
};
