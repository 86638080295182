import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/tpa-settings';

export interface IMASettingsParams {
  pageTitleText: SettingsParamType.Text;
  pageSubtitleText: SettingsParamType.Text;
  emptyChallengesText: SettingsParamType.Text;
  emptyChallengesLinkText: SettingsParamType.Text;
}

export default createSettingsParams<IMASettingsParams>({
  pageTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('members-area.title');
    },
  },
  pageSubtitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('members-area.subtitle');
    },
  },
  emptyChallengesText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('members-area.empty-state.text');
    },
  },
  emptyChallengesLinkText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('members-area.empty-state.link-text');
    },
  },
});
