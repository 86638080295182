import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
  wixFontParam,
} from '@wix/tpa-settings';
import { fontValueFix } from '../ChallengesList/stylesParams';

export type IMAStylesParams = {
  infoDetailsFontColor: StyleParamType.Color;
  infoDetailsFont: StyleParamType.Font;
  linksFontColor: StyleParamType.Color;
  linksFont: StyleParamType.Font;
  tabsDividerColor: StyleParamType.Color;
  tabsSelectedTabColor: StyleParamType.Color;
  tabsTextColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  emptyTextFont: StyleParamType.Font;
  emptyTextFontColor: StyleParamType.Color;
};

export default createStylesParams<IMAStylesParams>({
  infoDetailsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  infoDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 15,
        }),
        textPresets,
      );
    },
  },
  linksFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  linksFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 15,
        }),
        textPresets,
      );
    },
  },
  tabsDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  tabsSelectedTabColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  tabsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  emptyTextFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  emptyTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
});
