import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';

export const getBadgeProps = (
  participantState: ParticipantState,
): { tKey: string } => {
  if (userTypeHandlers.isJoinedAlready(participantState)) {
    return null;
  }

  if (participantState === ParticipantState.PAYMENT_STARTED_OFFLINE) {
    return { tKey: 'challenge.participant.offline-payment-pending' };
  }

  if (participantState === ParticipantState.JOIN_REQUESTED) {
    return {
      tKey: 'challenge.participant.join-requested',
    };
  }

  if (userTypeHandlers.isInPaymentState(participantState)) {
    return {
      tKey: 'members-area.challenge-badge.payment-required',
    };
  }

  if (ParticipantState.JOIN_REJECTED === participantState) {
    return {
      tKey: 'challenge.participant.join-rejected',
    };
  }

  if (ParticipantState.INVITED === participantState) {
    return {
      tKey: 'members-area.challenge-badge.invited',
    };
  }

  return null;
};
