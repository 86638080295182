import React from 'react';

import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getMediaPreview } from '../../../../selectors/media';
import { classes, st } from './ChallengeImage.st.css';

export const ChallengeImage: React.FC<{
  challenge: Challenge;
}> = (props) => {
  const { isMobile, isRTL } = useEnvironment();
  const title = props.challenge?.settings?.description?.title;
  const media = props.challenge?.settings?.description?.media;
  const imgUrl = getMediaPreview(media, 300, 200);

  return (
    <figure
      className={st(classes.root, {
        mobile: isMobile,
        rtl: isRTL,
      })}
    >
      {imgUrl ? (
        <img src={imgUrl} className={classes.image} alt={title} />
      ) : null}
    </figure>
  );
};
