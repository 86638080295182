import React from 'react';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';

import { classes } from './ChallengeItem.st.css';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  LocationContext,
  Pages,
} from '../../../../contexts/Location/LocationContext';
import { isChallengePaidWithoutParticipation } from './isChallengePaidWithoutparticipation';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';
import { PaidPlansContext } from '../../../../contexts/PaidPlans/paidPlansContext';

export interface IGoToChallenge {
  challenge: MemberChallenge;
}

export const GoToChallenge: React.FC<IGoToChallenge> = (props) => {
  const { t } = useTranslation();
  const locationCtx = React.useContext(LocationContext);
  const ppCtx = React.useContext(PaidPlansContext);
  const slug = props?.challenge?.challenge?.settings?.seo?.slug;

  return (
    <button
      role="link"
      className={classes.link}
      onClick={() => {
        locationCtx.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
          reloadPage: true,
        });
      }}
    >
      {userTypeHandlers.isJoinedAlready(
        props.challenge.summary?.participation?.state as any,
      ) && t('members-area.cta.go-to-challenge')}

      {isChallengePaidWithoutParticipation(
        props.challenge,
        ppCtx.userPaidPlans,
      ) && t('members-area.cta.complete-payment')}

      {!userTypeHandlers.isJoinedAlready(
        props.challenge.summary?.participation?.state as any,
      ) &&
        !isChallengePaidWithoutParticipation(
          props.challenge,
          ppCtx.userPaidPlans,
        ) &&
        t('members-area.cta.view-challenge')}
    </button>
  );
};
